import { News } from '@common/clients/api';

import { DisplayType } from '../NewsList';

import styles from './NewsListItem.module.scss';

interface ClassConfig {
    displayType?: DisplayType;
    isDossier?: boolean;
    category?: News['category'];
    isBlockType?: boolean;
}

export const getNewsItemClasses = ({
    displayType,
    isDossier,
    category,
    isBlockType,
}: ClassConfig): {
    classes: string[];
    dataItems: { [key: string]: string };
} => {
    const classes = [styles.NewsItem];
    const dataItems: { [key: string]: string } = {};

    if (displayType) {
        classes.push(styles[displayType]);
    }
    if (isDossier) {
        classes.push(styles.dossier);
    }
    if (category?.functionality) {
        classes.push(`${category.functionality}-article`);
    }
    if (category?.functionality === 'betting') {
        classes.push('ndm-betting-integration-tracker');
        dataItems['data-tracker-name'] = 'advertorialNewsList';
    }
    if (category?.functionality === 'pro') {
        classes.push(styles.pro);
    }
    if (isBlockType) {
        classes.push(styles['block-type']);
    }

    return { classes, dataItems };
};
