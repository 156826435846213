import { RefObject } from 'react';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { News, PlatformID, Tag } from '@common/clients/api';
import { WebpWidthEnum } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';
import {
    DateTimeUtil,
    Format,
    formatDuration,
    formatNumericDuration,
    Perspective,
} from '@common/utils/DateTimeUtil';
import { TagElement } from '@web/atoms/TagElement';
import { WebpImage } from '@web/atoms/WebpImage';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import { Flag } from '../Flag';
import { DisplayType } from '../NewsList';
import { ViBlock } from '../ViBlock';
import { getNewsItemClasses } from './getNewsItemClasses';

import styles from './NewsListItem.module.scss';

interface Props {
    newsItem: News;
    displayType?: DisplayType;
    isDossier?: boolean;
    firstElementRef?: RefObject<HTMLLIElement>;
    tag?: Tag;
}

export const NewsListItem = ({ newsItem, displayType, isDossier, firstElementRef, tag }: Props) => {
    const { platform, context } = useContextData();
    const __datetime = useTranslation('datetime').t;

    const date: Date = new Date(newsItem.newsDate);

    let path: string = '';
    if (newsItem.path) {
        if (newsItem.host && !newsItem.host.match(context.url)) {
            path = newsItem.host;
        }
        path += newsItem.path;
    }

    const isBlockType = displayType === DisplayType.blocks || displayType === DisplayType.carrousel;
    const { classes, dataItems } = getNewsItemClasses({
        displayType,
        isDossier,
        category: newsItem.category,
        isBlockType,
    });

    const isMini = displayType === DisplayType.compact || displayType === DisplayType.list;
    const isBold = newsItem?.titleIsBold;
    const imageSize = isMini ? WebpWidthEnum.WIDTH_110 : WebpWidthEnum.WIDTH_225;

    return (
        <li className={classes.join(' ')} ref={firstElementRef} {...dataItems}>
            {platform.id === PlatformID.VI && isBlockType ? (
                <ViBlock item={newsItem} />
            ) : (
                <>
                    {displayType === DisplayType.compact ? (
                        <PlatformToggle exclude={PlatformID.VI}>
                            {isDossier ? (
                                <span className={styles.radio} />
                            ) : (
                                <>
                                    <PlatformToggle include={[PlatformID.VP, PlatformID.VN]}>
                                        <Flag
                                            displayType={displayType}
                                            tag={newsItem.tags?.at(0)}
                                            platform={platform.id}
                                        />
                                    </PlatformToggle>
                                    <span className={styles.time} suppressHydrationWarning={true}>
                                        {newsItem?.newsVideo ? <Icon.play /> : null}
                                        {DateTimeUtil.format(date, Format.TIME_NUMERIC)}
                                    </span>
                                </>
                            )}
                        </PlatformToggle>
                    ) : null}
                    {displayType !== DisplayType.compact ? (
                        <Link href={path}>
                            <div className={styles['play-video']}>
                                {newsItem.category?.isMedia && !isMini ? (
                                    <>
                                        <PlatformToggle exclude={PlatformID.VI}>
                                            <span className={'play-button'}>
                                                <span className="icon"></span>
                                            </span>
                                        </PlatformToggle>
                                        {newsItem?.videofeedItem?.duration ? (
                                            <span className={styles.time}>
                                                {formatNumericDuration(newsItem.videofeedItem.duration)}
                                            </span>
                                        ) : null}
                                    </>
                                ) : null}
                                <WebpImage
                                    defaultSize={imageSize}
                                    className={styles.image}
                                    src={newsItem.image || ''}
                                    alt={''}
                                    ratio={110 / 60}
                                />
                            </div>
                        </Link>
                    ) : null}
                    {!isMini ? (
                        <div className={styles['tag-container']}>
                            <p>{newsItem.category?.name}</p>
                        </div>
                    ) : null}
                    <div className={isDossier ? styles.content + ' content' : styles.content}>
                        <Link href={path}>
                            <h4 className={isBold ? styles['extra-bold'] : ''}>
                                <div className={styles['title-wrapper']}>
                                    <PlatformToggle exclude={PlatformID.VI}>
                                        <TagElement item={newsItem} displayType={displayType} tag={tag} />
                                    </PlatformToggle>
                                    <PlatformToggle include={PlatformID.VI}>
                                        <>
                                            {newsItem.category?.functionality === 'pro' ? (
                                                <span className={styles['pro-tag']}>
                                                    <Icon.pro /> <TagElement item={newsItem} />
                                                </span>
                                            ) : null}
                                        </>
                                    </PlatformToggle>
                                    <p className={styles.title}>{newsItem.newsTitle}</p>
                                </div>
                            </h4>
                        </Link>
                        <PlatformToggle exclude={PlatformID.VI}>
                            <div className={styles.meta}>
                                <span className={styles['long-date']} suppressHydrationWarning={true}>
                                    {formatDuration({
                                        date,
                                        __translate: __datetime,
                                        perspective: Perspective.IN_AGO,
                                    })}
                                </span>
                                {newsItem.commentCount ? (
                                    <Link href={path + '#comments_anchor'}>
                                        <div className={styles.comments}>
                                            <Icon.comment />
                                            <span className={styles['comment-count']}>
                                                {newsItem.commentCount}
                                            </span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        </PlatformToggle>
                    </div>
                </>
            )}
        </li>
    );
};
